import React, { useEffect } from "react"
import { Header } from "../components/header"
import { Footer } from "../components/footer"
import houseSvg from "../images/house.svg"

const HouseImg = () => <img className="mx-auto pt-16" src={houseSvg} />

const Layout = ({ children, location }: { children: any }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex-1">
        {children}
        <HouseImg />
      </div>
      <Footer />
    </div>
  )
}

export default Layout
