import React from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export interface InternalLinkInt {
  _type: string
  link: {
    _id: string
    _type: "page"
    content: {
      main: {
        slug: {
          current: string
        }
      }
    }
  }
}

export interface ExternalLinkInt {
  _type: string
  link: string
}

export const DeductivePageLink = (props: {
  internalLink?: InternalLinkInt
  externalLink?: ExternalLinkInt
  className?: string
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onMouseOver?: () => void
  children?: any
}) => {
  const type = Boolean(props.internalLink) ? "internalLink" : "externalLink"
  const to = Boolean(props.internalLink)
    ? props.internalLink
    : props.externalLink

  const pageLinkProps = {
    ...props,
    type,
    to,
  }

  return <PageLink {...pageLinkProps}>{props.children}</PageLink>
}

export const PageLink = (props: {
  className?: string
  to: any
  type?: string
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onMouseOver?: () => void
  children?: any
}) => {
  if (props.type === "externalLink") {
    return (
      <a
        className={props.className}
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        onMouseOver={props.onMouseOver}
        href={props.to}
        target="_blank"
      >
        {props.children}
      </a>
    )
  }

  if (props.type === "anchorLink") {
    return (
      <AnchorLink to={props.to} className={props.className}>
        {props.children}
      </AnchorLink>
    )
  }

  let to = props.to

  if (props.type === "internalLink") {
    const slug = props.to.content.main.slug.current
    switch (props.to._type) {
      case "page":
        to = slug === "home" ? `/` : `/${slug}/`
        break
      default:
        to = `/${slug}/`
    }
  }

  return (
    <Link
      type={props.type}
      className={props.className}
      activeClassName="active"
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onMouseOver={props.onMouseOver}
      to={to}
    >
      {props.children}
    </Link>
  )
}
