import React, { useState } from "react"
import cx from "classnames"
import { StaticQuery, graphql } from "gatsby"
import { Close, Hamburger, Logo } from "./svg"
import { PageLink } from "./link"

const HeaderLinkList = ({
  list,
  className,
  expandNav,
}: {
  className?: string
  list: {
    title: string
  }[]
  expandNav: any
}) => (
  <ul className={`md:flex ${className}`}>
    {list.items.map(({ title, link, _type }, i) => (
      <li
        key={title}
        className={`mb-3 md:mb-0 ${
          i !== list.items.length - 1 ? "md:pr-5 lg:pr-10" : ""
        }`}
      >
        <PageLink
          type={_type}
          to={link}
          className="uppercase"
          onClick={() => expandNav(false)}
        >
          {title}
        </PageLink>
      </li>
    ))}
  </ul>
)

export const Header = () => {
  const [isExpanded, expandNav] = useState(false)
  return (
    <StaticQuery
      query={graphql`
        query HeaderQuery {
          sanityHeader {
            _rawHeaderLinksLeft(resolveReferences: { maxDepth: 10 })
            _rawHeaderLinksRight(resolveReferences: { maxDepth: 10 })
          }
        }
      `}
      render={data => {
        return (
          <>
            <div className="relative bg-cream flex flex-wrap justify-between items-center py-6 px-4 md:px-8 sticky top-0 z-10">
              <HeaderLinkList
                expandNav={expandNav}
                list={data.sanityHeader._rawHeaderLinksLeft}
                className="hidden md:block w-1/3"
              />
              <PageLink to="/" className="w-full md:w-1/3 block">
                <Logo className="h-6 md:h-8 text-evergreen mx-auto" />
              </PageLink>
              <HeaderLinkList
                expandNav={expandNav}
                list={data.sanityHeader._rawHeaderLinksRight}
                className="hidden md:block w-1/3 justify-end"
              />
              <button
                className="absolute right-0 mr-4 top-7 md:hidden"
                onClick={() => expandNav(!isExpanded)}
              >
                <Hamburger className={`${isExpanded ? "hidden" : "block"}`} />
                <Close className={`${isExpanded ? "block" : "hidden"}`} />
              </button>
              <div
                className={cx(
                  "md:hidden w-full pt-4 absolute bg-cream right-0 left-0 top-mnav px-4",
                  {
                    hidden: !isExpanded,
                    block: isExpanded,
                  }
                )}
              >
                <HeaderLinkList
                  expandNav={expandNav}
                  list={data.sanityHeader._rawHeaderLinksLeft}
                  className="text-right"
                />
                <HeaderLinkList
                  expandNav={expandNav}
                  list={data.sanityHeader._rawHeaderLinksRight}
                  className="text-right"
                />
              </div>
            </div>
          </>
        )
      }}
    />
  )
}
