import React from "react"
import { StaticQuery, graphql } from "gatsby"

export const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        sanityConfig {
          address
        }
      }
    `}
    render={data => {
      return (
        <div>
          <div className="bg-green text-white px-8 py-6 mt-16 text-center">
            <p className="lg:text-lg">{data.sanityConfig.address}</p>
          </div>
          <p className="text-sm text-center uppercase font-sans mb-0 tracking-wider">
            Site designed and built by{" "}
            <a href="http://amandakievet.com/" target="_blank">
              Amanda Kievet
            </a>
          </p>
        </div>
      )
    }}
  />
)
